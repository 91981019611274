import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
} from "react-icons/ai";
import {
  SiHackthebox,
  SiTryhackme,
} from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              I do believe <span className="purple"> introductions </span> are in order!
            </h1>
            <p className="home-about-body">
              I've been referred to as an "ultra geek" because of my love for technology and security.
              <br />
              <br />
              Since I was a child, I've been tinkering with computers, bypassing my father's security measures and learning new things.
              <br />
              <br />I have in depth experience with a vast array of
              <i>
                <b className="purple"> SIEMs, SOARs, EDR/XDR Platforms and Pentesting Tools.</b>
              </i>
              <br />
              <br />
              My past experience makes me a valuable asset to your business, with experience in &nbsp;
              <i>
                <b className="purple">Network and Systems Engineering</b> as
                well as{" "}
                <b className="purple">
                  Full Spectrum Managed Services.
                </b>
              </i>
              <br />
              <br />
              Whenever I have time, I enjoy furthering my education with a fully equipped
              with <b className="purple">Homelab</b> which includes a
              <i>
                <b className="purple">
                  {" "}
                  FortiGate Firewall and Switch, ESXi Hypervisor with Pentesting OSes and Log Management
                </b>
              </i>
              &nbsp; in addition to
              <i>
                <b className="purple"> TryHackMe and HackTheBox </b> to continuously expand my skillset.
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Relevant Social Media</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me!
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/cyberweet"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/w33t/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
              <a
                href="https://app.hackthebox.com/profile/353518"
                target="_blank" 
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <SiHackthebox />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://tryhackme.com/p/weet"
                target="_blank" 
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <SiTryhackme />
              </a>
            </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
