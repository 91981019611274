import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Cybersecurity Engineer",
          "Penetration Tester",
          "Risk Assessment Analyst",
          "OSINT Wizard",
          "Connoisseur of Memes",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
